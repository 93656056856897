const initialState = {
	isAboutOpen: false,
	isArchiveOpen: false,
	isArchiveFiltersOpen: false,
	archiveAudioPlayerItem: null,
	videoAccessibilityMode: 'default',
	isVideoPlayerAccessibilityControlsOpen: false,
	isVideoPlayerSecondaryControlsOpen: false,
	// isOnlyFeaturedInArtwork: false,
};

export const ui = (state, action) => {

	function onFirstTouch() {
		document.body.classList.add('touchscreen');
		window.removeEventListener('touchstart',onFirstTouch);
	}

	window.addEventListener('touchstart',onFirstTouch);

	if (typeof state === 'undefined') {
		return initialState;
	}

	switch (action.type) {
	case 'APP_LOADED':
		return { ...state, ready: true };
	case 'SET_IS_ABOUT_OPEN':
		return { ...state, isAboutOpen: action.payload };
	case 'SET_IS_ARCHIVE_OPEN':
		return { ...state, isArchiveOpen: action.payload };
	case 'SET_IS_ARCHIVE_FILTERS_OPEN':
		return { ...state, isArchiveFiltersOpen: action.payload };
	case 'SET_IS_VIDEO_PLAYER_ACCESSIBILITY_CONTROLS_OPEN':
		return { ...state, isVideoPlayerAccessibilityControlsOpen: action.payload};
	case 'SET_IS_VIDEO_PLAYER_SECONDARY_CONTROLS_OPEN':
		return { ...state, isVideoPlayerSecondaryControlsOpen: action.payload};
	// case 'TOGGLE_ONLY_FEATURED_IN_ARTWORK':
	// 	return { ...state, isOnlyFeaturedInArtwork: action.payload};
	case 'CLOSE_OVERLAYS':
		return {
			...state,
			isArchiveOpen: false,
			isAboutOpen: false,
			archiveAudioPlayerItem: null,
			isArchiveFiltersOpen: false
		};
	case 'SET_ARCHIVE_AUDIO_PLAYER_ITEM':
			return { ...state, archiveAudioPlayerItem: action.payload };
	case 'SET_VIDEO_ACCESSIBILITY_MODE':
		return { ...state, videoAccessibilityMode: action.payload };
	default:
		return state;
	}
};
