import React from 'react';

import classNames from 'classnames';

const OverlayLayout = ({ children, isOpen }) => {
    const className = classNames({
        'overlay-layout': true,
        'overlay-layout--is-open': isOpen
    });

    return (
        <div className={className} aria-hidden={!isOpen}>
            <div className="overlay-layout__inner">
                { children }
            </div>
        </div>
    )
}

export default OverlayLayout;