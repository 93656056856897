import React from 'react';
import { connect } from 'react-redux';
import HeaderLogo from '../../assets/HeaderLogo.js';
import { mapDispatchToProps } from '../../store/store.js';

import Button from '../Core/Button/Button.js';
import SearchInput from '../SearchInput/SearchInput.js';

class Header extends React.Component {
    state = {
        textSize: 'small',
    }

    constructor(props) {
        super(props);

        this.makeTextSmaller = this.makeTextSmaller.bind(this);
        this.makeTextLarger = this.makeTextLarger.bind(this);
    }

    makeTextSmaller() {
        document.documentElement.classList.remove('large-text');
        this.setState({ textSize: 'small' });
    }

    makeTextLarger() {
        document.documentElement.classList.add('large-text');
        this.setState({ textSize: 'large' });
    }

    render() {
        const { isOverlayOpen, isArchiveFiltersOpen, isArchiveOpen, archiveFilterCount, closeOverlays, setIsAboutOpen, setIsArchiveOpen, setIsArchiveFiltersOpen, isOnlyFeaturedInArtwork } = this.props;
        const { textSize } = this.state;

        let filtersPlusOnlyInArtwork = archiveFilterCount;
        
        if (isOnlyFeaturedInArtwork) {
            filtersPlusOnlyInArtwork = archiveFilterCount + 1;
        }
        
        return (
            <header className="header">
                <div className="header__inner">
                    {isOverlayOpen ?
                        <Button onClick={closeOverlays} label="Close overlay">Back</Button>
                    : null}
        
                    {!isOverlayOpen ?
                        <Button onClick={() => setIsAboutOpen(true)} label="Open about">About</Button>
                    : null}
        
                        {!isOverlayOpen ?
                            <h1 className="header__title">The Texture of Air</h1>
                            // <HeaderLogo />
                        : null}
        
                        {isArchiveOpen ? 
                            <div className="header__items--center">
        
                                {!isArchiveFiltersOpen ?
                                    <SearchInput />
                                : null}
        
                                <Button
                                    classes="header__button"
                                    label={ isArchiveFiltersOpen ? 'Apply Filters' : 'Show Filters'}
                                    onClick={() => setIsArchiveFiltersOpen(!isArchiveFiltersOpen)}
                                >{ isArchiveFiltersOpen ? 
                                        <>
                                            <span>Apply </span> 
                                            <span className="hide-on-mobile">Filters</span> 
                                        </>
                                    : 
                                        <>
                                            <span className="hide-on-mobile">Show</span> Filter<span className="hide-on-mobile">s</span>
                                        </>
                                    }
                                    {filtersPlusOnlyInArtwork > 0 ?
                                        <span className="header__filter-count"> ({filtersPlusOnlyInArtwork})</span>
                                    : null}
                                </Button>
                            </div>
                        : null}
        
        
                    {!isOverlayOpen ?
                        <Button onClick={() => setIsArchiveOpen(true)} label="Open archive">Archive</Button>
                        :
                    <div className="text-size-controls">    
                        <Button onClick={this.makeTextSmaller} label="Make Text Smaller" classes="text-size-button header__button text-size-button--small" isActive={textSize === 'small'}>aA</Button>
                        <Button onClick={this.makeTextLarger} label="Make Text Larger" classes="text-size-button header__button text-size-button--large" isActive={textSize === 'large'}>aA</Button>
                    </div>
                 }
                </div>
            </header>    
        )
    }
}

const mapStateToProps = state => {
    return {
        isOverlayOpen: state.ui.isAboutOpen || state.ui.isArchiveOpen,
        isArchiveOpen: state.ui.isArchiveOpen,
        isArchiveFiltersOpen: state.ui.isArchiveFiltersOpen,
        archiveFilterCount: state.filterSearch.filters.length,
        isOnlyFeaturedInArtwork: state.filterSearch.isOnlyFeaturedInArtwork,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);