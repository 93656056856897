const initialState = {
	featureSupport: {},
	device: {},
	siteData: null,
};

export const app = (state, action) => {
	if (typeof state === 'undefined') {
		return initialState;
	}

	switch (action.type) {
	case 'SET_FEATURE_SUPPORT':
		return { ...state, featureSupport: action.payload };
	case 'SET_DEVICE':
		return { ...state, device: action.payload };
	case 'SET_SITE_DATA':
		return { ...state, siteData: action.payload };
	default:
		return state;
	}
};
