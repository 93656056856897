import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../store/store.js';

import Button from '../Core/Button/Button.js';

const Toggle = ({ filterKey, value, filters, isVisible, toggleFilter, displayName }) => {
    const isActive = filters.find((filter) => filter.key === filterKey && filter.value === value.toLowerCase());

    return (
        <Button
            label={`Toggle ${value} ${isActive ? 'Off' : 'On'}`}
            tabIndex={isVisible ? 0 : -1}
            isActive={isActive}
            onClick={() => toggleFilter({ key: filterKey, value: value.toLowerCase() })}
            classes="archive-filters__toggle"
        >{displayName}</Button>
    )
}

// const InArtworkToggle = ({ toggleOnlyFeaturedInArtwork, isOnlyFeaturedInArtwork, displayName, isActive, isVisible }) => {
//     return (
//         <Button
//             label="Toggle Only Items Featured in Artwork"
//             tabIndex={isVisible ? 0 : -1}
//             isActive={isActive}
//             onClick={() => toggleOnlyFeaturedInArtwork(!isOnlyFeaturedInArtwork)}
//             classes="archive-filters__toggle"
//         >{displayName}</Button>
//     )
// }

const ArchiveFilters = ({ isVisible, filters, filtersCount, toggleFilter, clearFilters, toggleOnlyFeaturedInArtwork, isOnlyFeaturedInArtwork }) => {
    let singularOrPlural = 'Filter';
    if (filtersCount > 1 || (filtersCount > 0 && isOnlyFeaturedInArtwork)) {
        singularOrPlural = 'Filters';
    }

    let filtersPlusOnlyInArtwork = filtersCount;
    if (isOnlyFeaturedInArtwork) {
        filtersPlusOnlyInArtwork = filtersCount + 1;
    }

    let showClear = filtersCount > 0 || isOnlyFeaturedInArtwork;
    return (
        <div className="archive-filters">
            {showClear ?
                <section className="archive-filters__section">
                    <Button
                        value="Clear filters"
                        isDisabled={filtersCount === 0 && isOnlyFeaturedInArtwork === false}
                        tabIndex={isVisible ? 0 : -1}
                        onClick={clearFilters}
                    >Clear {filtersPlusOnlyInArtwork} {singularOrPlural}</Button>
                </section>
            : null}
            
            <section className="archive-filters__section">
                <h3 className="archive-filters__subtitle">Type</h3>
                <Toggle toggleFilter={toggleFilter} displayName="Oral History" value="Oral History" filterKey="type" filters={filters} isVisible={isVisible} />
                {/* <Toggle toggleFilter={toggleFilter} displayName="Image" value="Image" filterKey="type" filters={filters} isVisible={isVisible} /> */}
                <Toggle toggleFilter={toggleFilter} displayName="Field Recording" value="Field Recording" filterKey="type" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Show & Tell" value="Show & Tell" filterKey="type" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Audio Moment" value="Audio Moment" filterKey="type" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Music" value="Music" filterKey="type" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Transcription" value="Transcription" filterKey="type" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="16mm Photo" value="16mm Photo" filterKey="type" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Lidar Scan" value="Lidar Scan" filterKey="type" filters={filters} isVisible={isVisible} />
                {/* <Toggle toggleFilter={toggleFilter} displayName="Other" value="Other" filterKey="type" filters={filters} isVisible={isVisible} /> */}
            </section>

            <section className="archive-filters__section">
                <h3 className="archive-filters__subtitle">Site</h3>
                <Toggle toggleFilter={toggleFilter} displayName="Royal National Ear Nose and Throat Hospital" value="RNTNEH" filterKey="site" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Eastman Dental Hospital" value="EDH" filterKey="site" filters={filters} isVisible={isVisible} />
                {/* <Toggle toggleFilter={toggleFilter} displayName="Other" value="Other" filterKey="site" filters={filters} isVisible={isVisible} /> */}
            </section>

            <section className="archive-filters__section">
                <h3 className="archive-filters__subtitle">Department</h3>
                <Toggle toggleFilter={toggleFilter} displayName="Diagnosis & Consultation" value="Diagnosis & Consultation" filterKey="department" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Medical Procedures & Treatments" value="Medical Procedures & Treatments" filterKey="department" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Rehabilitation & Therapy" value="Rehabilitation & Therapy" filterKey="department" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Implants & Prosthetics" value="Implants & Prosthetics" filterKey="department" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Community & Shared Spaces" value="Community & Shared Spaces" filterKey="department" filters={filters} isVisible={isVisible} />
                {/* <Toggle toggleFilter={toggleFilter} displayName="Other" value="Other" filterKey="department" filters={filters} isVisible={isVisible} /> */}
            </section>

            <section className="archive-filters__section">
                <h3 className="archive-filters__subtitle">Format</h3>
                <Toggle toggleFilter={toggleFilter} displayName="Audio" value="Audio" filterKey="format" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Image" value="Image" filterKey="format" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Text" value="Text" filterKey="format" filters={filters} isVisible={isVisible} />
            </section>

            <section className="archive-filters__section">
                <h3 className="archive-filters__subtitle">Person</h3>
                <Toggle toggleFilter={toggleFilter} displayName="Staff" value="Staff" filterKey="author" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Patient" value="Patient" filterKey="author" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Volunteer" value="Volunteer" filterKey="author" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Artist" value="Artist" filterKey="author" filters={filters} isVisible={isVisible} />
                {/* <Toggle toggleFilter={toggleFilter} displayName="Other" value="Other" filterKey="author" filters={filters} isVisible={isVisible} /> */}
            </section>

            <section className="archive-filters__section">
                <h3 className="archive-filters__subtitle">Body Part</h3>
                <Toggle toggleFilter={toggleFilter} displayName="Ear" value="Ear" filterKey="relatesTo" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Nose" value="Nose" filterKey="relatesTo" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Throat" value="Throat" filterKey="relatesTo" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Teeth" value="Teeth" filterKey="relatesTo" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Face" value="Face" filterKey="relatesTo" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Building" value="Building" filterKey="relatesTo" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Object" value="Object" filterKey="relatesTo" filters={filters} isVisible={isVisible} />
                {/* <Toggle toggleFilter={toggleFilter} displayName="Other" value="Other" filterKey="relatesTo" filters={filters} isVisible={isVisible} /> */}
            </section>

            <section className="archive-filters__section">
                <h3 className="archive-filters__subtitle">Video Chapters</h3>
                <Toggle toggleFilter={toggleFilter} displayName="One" value="Chapter One" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Two" value="Chapter Two" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Three" value="Chapter Three" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Four" value="Chapter Four" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Five" value="Chapter Five" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Six" value="Chapter Six" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Seven" value="Chapter Seven" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Eight" value="Chapter Eight" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Nine" value="Chapter Nine" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Ten" value="Chapter Ten" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Eleven" value="Chapter Eleven" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Twelve" value="Chapter Twelve" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
                <Toggle toggleFilter={toggleFilter} displayName="Thirteen" value="Chapter Thirteen" filterKey="chapterInArtwork" filters={filters} isVisible={isVisible} />
            </section>

            {/* <section className="archive-filters__section">
                <InArtworkToggle
                    displayName="Show Featured In Artwork"
                    toggleOnlyFeaturedInArtwork={toggleOnlyFeaturedInArtwork}
                    isActive={isOnlyFeaturedInArtwork === true ? true : false }
                    isOnlyFeaturedInArtwork={isOnlyFeaturedInArtwork}
                    isVisible={isVisible}
                />
            </section> */}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        filters: state.filterSearch.filters,
        isOnlyFeaturedInArtwork: state.filterSearch.isOnlyFeaturedInArtwork,
        filtersCount: state.filterSearch.filters.length,
        isVisible: state.ui.isArchiveFiltersOpen,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveFilters);