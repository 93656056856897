import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../store/store.js';

const SearchInput = ({ searchInput, setSearchInput }) => {
    return (
        <input className="search-input" type="text" value={searchInput || ''} placeholder="Search" onChange={(e) => {
            setSearchInput(e.target.value);
        }} />
    )
}

const mapStateToProps = state => {
    return {
        searchInput: state.filterSearch.searchInput,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);