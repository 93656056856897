import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { mapDispatchToProps } from '../../store/store.js';

import OverlayLayout from '../../layouts/OverlayLayout/OverlayLayout.js';
import Transcript from '../../components/Transcript/Transcript.js';
import Button from '../../components/Core/Button/Button.js';
import PlayPauseButton from '../PlayPauseButton/PlayPauseButton.js';
import ProgressBar from '../ProgressBar/ProgressBar';

// TODO: work out why progress bar is causing an error
// TODO: make audio player autoplay when play is clicked on archive item (instead of having to click again on audio player play button)

class ArchiveAudioPlayer extends React.Component {
    state = {
        isPlaying: false,
        isTranscriptOpen: false,
        currentTime: 0,
        duration: 0,
    }

    constructor(props) {
        super(props);

        this.onCanPlay = this.onCanPlay.bind(this);
        this.togglePlayPause = this.togglePlayPause.bind(this);
        this.setCurrentTime = this.setCurrentTime.bind(this);
        this.onPlaying = this.onPlaying.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onEnded = this.onEnded.bind(this);
    }

    audioElRef = React.createRef();
    getTimeInterval = null;

    onCanPlay() {
        requestAnimationFrame(() => {
            this.setState({ duration: this.audioElRef.current.duration });
        });
    }

    togglePlayPause() {
        const { isPlaying } = this.state;
        if (isPlaying) {
            this.audioElRef.current.pause();
        } else {
            this.audioElRef.current.play();
        }
    }

    setCurrentTime(time) {
        let clampedTime = time;
        const { duration } = this.state;
        if (time < 0) clampedTime = 0;
        if (time > duration) clampedTime = duration;
        
        this.audioElRef.current.currentTime = clampedTime;
        this.setState({ currentTime: clampedTime });
    }

    onPlaying() {
        this.setState({ isPlaying: true });
        clearInterval(this.getTimeInterval);
        this.getTimeInterval = setInterval(() => {
            if (!this.audioElRef.current) return;
            requestAnimationFrame(() => {
                this.setState({ currentTime: this.audioElRef.current.currentTime });
            });
        }, 99);
    }

    onPause() {
        this.setState({ isPlaying: false });
        clearInterval(this.getTimeInterval);
    }

    onEnded() {
        this.setState({ isPlaying: false });
        clearInterval(this.getTimeInterval);
        this.setCurrentTime(0);
    }

    componentWillUpdate(nextProps) {
        if (!nextProps.isVisible && this.state.isTranscriptOpen) {
            this.setState({ isTranscriptOpen: false });
        }
    }

    componentDidUpdate(lastProps) {
        if (lastProps.data.src !== this.props.data.src) {
            this.audioElRef.current.play();
        }

        if (lastProps.data.src && !this.props.data.src) {
            this.audioElRef.current.pause();
        }
    }

    render() {
        const { isPlaying, isTranscriptOpen, currentTime, duration } = this.state;
        const { isVisible, data } = this.props;
        const { title, transcript, src } = data;

        const className = classNames({
            'archive-audio-player': true,
            'archive-audio-player--is-visible': isVisible
        });

        return (
            <>
            <OverlayLayout isOpen={isTranscriptOpen}>
                <div className="overlay-layout__content">
                    <Transcript content={transcript} />
                </div>
            </OverlayLayout>
            <div className="archive-audio-player__wrapper">
                <div className={className} aria-hidden={!isVisible}>
                    <div className="archive-audio-player__inner">
                        <PlayPauseButton onClick={this.togglePlayPause} tabIndex={isVisible ? 0 : -1} isPlaying={isPlaying} classes="archive-audio-player__play-pause-button" />
                        <div className="archive-audio-player__info-container">
                            <h4 className="archive-audio-player__title">{title}</h4>
                            {transcript ? 
                                <Button
                                    label={ isTranscriptOpen ? 'Close transcript' : 'Open transcript'}
                                    tabIndex={isVisible ? 0 : -1}
                                    onClick={() => this.setState({ isTranscriptOpen: !isTranscriptOpen })}
                                    isActive={isTranscriptOpen}
                                    reverseColour
                                >{ isTranscriptOpen ? 'Close Transcript' : 'Open Transcript'}</Button>
                            : null }
                        </div>
                        <audio 
                            ref={this.audioElRef}
                            onPlaying={this.onPlaying}
                            onPause={this.onPause}
                            onCanPlay={this.onCanPlay}
                            id="audio"
                            src={src}
                        >
                        </audio>
                        <ProgressBar currentTime={currentTime} duration={duration} setCurrentTime={this.setCurrentTime} isVisible={isVisible} />
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.ui.archiveAudioPlayerItem || {},
        isVisible: !!state.ui.archiveAudioPlayerItem,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ArchiveAudioPlayer);