import { createStore, bindActionCreators, applyMiddleware, compose } from 'redux';
import { get as _get } from 'lodash';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import rootReducer from './reducers/index';

import { connect } from 'react-redux';
import * as actionCreators from './actions/actionCreators';

const middleware = applyMiddleware(promiseMiddleware, thunk);

function RunDevToolExtensionIfNotInProduction() {
	const shouldExposeState = (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') &&
                            window.devToolsExtension;

	return (shouldExposeState ? window.devToolsExtension() : (f) => f);
}

const configureStore = () => {
	const store = createStore(
		rootReducer,
		compose(middleware, RunDevToolExtensionIfNotInProduction())
	);

	if (process.env.NODE_ENV !== 'production') {
		if (module.hot) {
			module.hot.accept('./reducers/index.js', () => {
				store.replaceReducer(rootReducer);
			});
		}
	}

	return store;
};

export const store = configureStore();


export function mapDispatchToProps(dispatch) {
	return bindActionCreators(actionCreators, dispatch);
}

export function connectComponentGet(component, pick = []) {
	const mapStateToProps = state => {
		const props = {};

		pick.forEach(item => {
			let path; let key;
			if (Array.isArray(item)) {
				[path, key] = item;
			} else {
				path = item;
				key = path.split('.').slice(-1).pop();
			}
			const value = _get(state, path);
			props[key] = value;
		});

		return props;
	};

	return connect(mapStateToProps, mapDispatchToProps)(component);
}