import React from 'react';

const PauseSymbol = () => {
	return (
		<svg 
			viewBox="0 0 20 21.67"
			className="pause-symbol"
		>
		<title>PauseSymbol</title>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<rect className="cls-1" width="6.67" height="21.67"/>
					<rect className="cls-1" x="13.33" width="6.67" height="21.67"/>
				</g>
			</g>
		</svg>
	)
}

export default PauseSymbol;