import React from 'react';
import { connect } from 'react-redux';

import PlayerControls from '../PlayerControls/PlayerControls.js';
import PlayPauseButton from '../PlayPauseButton/PlayPauseButton.js';

import videoSubtitles from '../../assets/texture-of-air-artwork-captions--med.mp4';
import videoDefault from '../../assets/texture-of-air-artwork--med.mp4';


class PlayerView extends React.Component {
    state = {
        videoSrc: null,
        currentVideo: 'default',
        isPlaying: false,
        currentTime: 0,
        duration: 0,
        resumeTime: null,
        resumePlaybackAuto: false,
        currentTitle: 'Chapter One',
    }

    videoElRef = React.createRef();
    videoElRefSubs = React.createRef();

    getTimeInterval = null;

    constructor(props) {
        super(props);
        this.togglePlayPause = this.togglePlayPause.bind(this);
        this.setCurrentTime = this.setCurrentTime.bind(this);
        this.onPlaying = this.onPlaying.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.onGetTime = this.onGetTime.bind(this);
        this.getCurrentVideoRef = this.getCurrentVideoRef.bind(this);
    }

    componentDidMount() {
        const setTimeFromArchiveItem = (e) => {
            let thisTime = parseFloat(e.target.getAttribute('data-starttime'));
            this.setCurrentTime(thisTime);
        }

        this.changeVideoAccessibilityMode(this.props.videoAccessibilityMode);
        this.videoElRef.current.load();
        this.videoElRefSubs.current.load();

        // add in listener here for set current time thing
        let inArtworkRow = document.getElementsByClassName('in-artwork__row');
        if (inArtworkRow[0]) {
            for (let i = 0; i < inArtworkRow.length; i++) {
                inArtworkRow[i].removeEventListener('click',setTimeFromArchiveItem);
                inArtworkRow[i].addEventListener('click',setTimeFromArchiveItem);
            }
        }
    }

    componentWillUpdate(nextProps) {
        const { isOverlayOpen, videoAccessibilityMode } = this.props;
        const { resumePlaybackAuto, isPlaying } = this.state;

        if (isOverlayOpen && !nextProps.isOverlayOpen) {
            // start playing the video again, but only if it was paused automatically
            if (resumePlaybackAuto) {
                this.getCurrentVideoRef().play();
                this.setState({ isPlaying: true, resumePlaybackAuto: false });
            }
        } else if (!isOverlayOpen && nextProps.isOverlayOpen) {
            // pause the video when it's being overlayed, and set a flat to make it auto-resume
            if (isPlaying) {
                this.getCurrentVideoRef().pause();
                this.setState({ isPlaying: false, resumePlaybackAuto: true });
            }
        }

        if (videoAccessibilityMode !== nextProps.videoAccessibilityMode) {
            this.changeVideoAccessibilityMode(nextProps.videoAccessibilityMode);
        }
    }

    changeVideoAccessibilityMode(currentVideo) {
        const { isPlaying } = this.state;
        this.getCurrentVideoRef().pause();
        const resumeTime = this.getCurrentVideoRef().currentTime;
        const resumePlaybackAuto = isPlaying;
        this.setState({ resumeTime, currentVideo });

        requestAnimationFrame(() => {
            this.getCurrentVideoRef().currentTime = resumeTime;
            if (resumePlaybackAuto) {
                this.getCurrentVideoRef().play();
                this.getCurrentVideoRef().muted = false;
            }
        });
    }

    getCurrentVideoRef() {
        const { currentVideo } = this.state;

        if (currentVideo === 'default') {
            return this.videoElRef.current;
        }

        if (currentVideo === 'subtitles') {
            return this.videoElRefSubs.current;
        }
    }

    getAllVideoRefs() {
        return [this.videoElRefSubs.current, this.videoElRef.current];
    }

    // onCanPlay() {

    //     console.log('on can play');
    //     const { resumeTime, resumePlaybackAuto } = this.state;

    //     this.setState({ duration: this.getCurrentVideoRef().duration });

    //     if (resumeTime) {
    //         this.getCurrentVideoRef().currentTime = resumeTime;
    //         this.setState({ resumeTime: null });
    //     }
        
    //     if (resumePlaybackAuto) {
    //         this.getCurrentVideoRef().play();
    //         this.setState({ isPlaying: true });
    //     }
    // }

    togglePlayPause() {
        const { isPlaying, resumeTime } = this.state;
        this.getCurrentVideoRef().muted = false;

        if (isPlaying) {
            this.getAllVideoRefs().forEach(el => el.pause());
        } else {
            if (resumeTime) {
                this.getCurrentVideoRef().currentTime = resumeTime;
                this.setState({ resumeTime: null });
            }
            this.getAllVideoRefs().forEach(el => el.pause());
            this.getCurrentVideoRef().play();
        }
        
    }

    setCurrentTime(time) {
        const { duration } = this.state;
        let clampedTime = time;

        if (time < 0) clampedTime = 0;
        if (time > duration) clampedTime = duration;

        this.getCurrentVideoRef().currentTime = Math.round(clampedTime);
        this.setState({ currentTime: clampedTime });
        this.onGetTime();
    }

    onGetTime() {
        if (!this.getCurrentVideoRef()) return;
        requestAnimationFrame(() => {
            const currentTime = this.getCurrentVideoRef().currentTime;
            const currentTitle = (() => {
                if (currentTime < 95) return 'Chapter One';
                if (currentTime < 174) return 'Chapter Two';
                if (currentTime < 244) return 'Chapter Three';
                if (currentTime < 377) return 'Chapter Four';
                if (currentTime < 440) return 'Chapter Five';
                if (currentTime < 552) return 'Chapter Six';
                if (currentTime < 660) return 'Chapter Seven';
                if (currentTime < 706) return 'Chapter Eight';
                if (currentTime < 820) return 'Chapter Nine';
                if (currentTime < 856) return 'Chapter Ten';
                if (currentTime < 1035) return 'Chapter Eleven';
                if (currentTime < 1172) return 'Chapter Twelve';
                return 'Chapter Thirteen';
            })();
            this.setState({
                currentTime,
                currentTitle,
                duration: this.getCurrentVideoRef().duration
            });
        });
    }

    onPlaying() {
        this.setState({ isPlaying: true });
        clearInterval(this.getTimeInterval);
        this.getTimeInterval = setInterval(this.onGetTime, 99);
    }

    onPause() {
        this.setState({ isPlaying: false });
        clearInterval(this.getTimeInterval);
    }

    onEnded() {
        this.setState({ isPlaying: false });
        clearInterval(this.getTimeInterval);
        this.setCurrentTime(0);
    }

    render() {
        const { isPlaying, currentTime, duration, currentVideo, currentTitle } = this.state;

        return (
            <>
                <div className="video-player">
                    <video
                        ref={this.videoElRef}
                        className={`video-player__video video-player__video--default ${currentVideo === 'default' ? 'video-player__video--current' : ''}`}
                        src={videoDefault}
                        // onCanPlay={this.onCanPlay}
                        onPlaying={this.onPlaying}
                        onPause={this.onPause}
                        onEnded={this.onEnded}
                        muted
                        playsInline
                        preload="auto"
                    />
                    <video
                        ref={this.videoElRefSubs}
                        className={`video-player__video video-player__video--subtitles ${currentVideo === 'subtitles' ? 'video-player__video--current' : ''}`}
                        src={videoSubtitles}
                        // onCanPlay={this.onCanPlay}
                        onPlaying={this.onPlaying}
                        onPause={this.onPause}
                        onEnded={this.onEnded}
                        muted
                        playsInline
                        preload="auto"
                    />
                </div>
                <PlayPauseButton onClick={this.togglePlayPause} isPlaying={isPlaying} classes={`video-player__play-pause-button ${isPlaying ? 'video-player__play-pause-button--hidden' : ''}`} />
                <PlayerControls
                    isPlaying={isPlaying}
                    currentTime={currentTime}
                    duration={duration}
                    togglePlayPause={this.togglePlayPause}
                    setCurrentTime={this.setCurrentTime}
                    currentTitle={currentTitle}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        isOverlayOpen: state.ui.isAboutOpen || state.ui.isArchiveOpen,
        videoAccessibilityMode: state.ui.videoAccessibilityMode,
    }
}

export default connect(mapStateToProps)(PlayerView);