import React from 'react';
import { connect } from 'react-redux';

import ArchiveHeaderButton from './ArchiveHeaderButton.js';

const ArchiveHeader = ({ tabIndex }) => {
    return (
        <div className="archive-header">
            <ArchiveHeaderButton colIndex={0} tabIndex={tabIndex} sortKey="title" label="Title" />
            <ArchiveHeaderButton colIndex={1} tabIndex={tabIndex} sortKey="type" label="Type" />
            <ArchiveHeaderButton colIndex={2} tabIndex={tabIndex} sortKey="site" label="Site" />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        tabIndex: state.ui.isArchiveOpen ? 0 : -1,
    }
}
export default connect(mapStateToProps)(ArchiveHeader);