const initialState = {
	searchInput: null,
	onlyFeaturedInArtwork: false,
	sort: { key: 'title', direction: 1 },
	filters: [],
};

// TODO: We probably won't have an 'applied tag' in the end: It will be presented as a search result set.
// Therefore, applied tags, remove tag and apply tag should be removed and cleaned up
export const filterSearch = (state, action) => {
	if (typeof state === 'undefined') {
		return initialState;
	}
	const newState = { ...state };

	switch (action.type) {
	case 'SET_SORT':
		newState.sort = action.payload;
		return newState;
	case 'SET_SEARCH_INPUT':
		return { ...state, searchInput: action.payload };
	case 'CLEAR_FILTERS':
		return { ...state, filters: [], isOnlyFeaturedInArtwork: false };
	case 'TOGGLE_FILTER':
		const currentIndex = state.filters.findIndex(item => item.key === action.payload.key && item.value === action.payload.value);

		let newFilters = [...state.filters];
		if (currentIndex === -1) {
			newFilters.push(action.payload);
		} else {
			newFilters.splice(currentIndex, 1);
		}

		return { ...state, filters: newFilters };
	case 'CLOSE_OVERLAYS':
		return {
			...state,
			filters: [],
			onlyFeaturedInArtwork: false,
			sort: { key: 'title', direction: 1 },
		};
	case 'TOGGLE_ONLY_FEATURED_IN_ARTWORK':
		return { ...state, isOnlyFeaturedInArtwork: !state.isOnlyFeaturedInArtwork };
	default:
		return state;
	}
};
