import React from 'react';
import classNames from 'classnames';

import PlaySymbol from '../../assets/PlaySymbol.js';
import PauseSymbol from '../../assets/PauseSymbol.js';

const PlayPauseButton = ({ onClick, isPlaying, classes = '' }) => {
    const className = classNames({
        'play-pause-button': true,
        'play-pause-button--is-playing': isPlaying,
        'play-pause-button--is-paused': !isPlaying,
    });

    return (
        <button className={`${className} ${classes}`} onClick={onClick} aria-label={isPlaying ? 'Pause' : 'Play'}>
            {isPlaying ? <PauseSymbol />: <PlaySymbol />}
        </button>
    )
}

export default PlayPauseButton;