import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../store/store.js';

import OverlayLayout from '../../layouts/OverlayLayout/OverlayLayout.js';
import MetadataTable from '../../components/Core/MetadataTable/MetadataTable.js';
import MainLogo from '../../assets/MainLogo.png';

class AboutView extends React.Component {
    contentEl = React.createRef();

    componentDidMount() {
        this.disableLinks();
    }

    componentDidUpdate(lastProps) {
        const { isOpen } = this.props;

        if (isOpen && !lastProps.isOpen) {
            this.enableLinks();
        }

        if (!isOpen && lastProps.isOpen) {
            this.disableLinks();
        }
    }

    enableLinks() {
        const linkEls = [...this.contentEl.current.querySelectorAll('a')];
        linkEls.forEach(el => el.tabIndex = null);
    }

    disableLinks() {
        const linkEls = [...this.contentEl.current.querySelectorAll('a')];
        linkEls.forEach(el => el.tabIndex = "-1");
    }

    render() {
        const { data, isOpen } = this.props;
        const { body, creditsText, colophonText, downloadsText, credits, downloads, supporterLogos = [] } = data;
        
        return (
            <OverlayLayout isOpen={isOpen}>
                <div className="overlay-layout__content" ref={this.contentEl}>
                    <article className="about">
                        <img className="about-section__main-logo"
                        src={MainLogo} />
                        <section className="about__section" dangerouslySetInnerHTML={{ __html: body }} />
                        <section className="about__section">
                            <h2>Credits</h2>
                            <div dangerouslySetInnerHTML={{ __html: creditsText }} />
                            <MetadataTable rows={credits} />
                        </section>
                        <section className="about__section">
                            <h2>Colophon</h2>
                            <div dangerouslySetInnerHTML={{ __html: colophonText }} />
                        </section>
                        <section className="about__section">
                            <h2>Downloads</h2>
                            <div dangerouslySetInnerHTML={{ __html: downloadsText }} />
                            <MetadataTable isVisible={isOpen} rows={downloads} />
                        </section>
                        <section className="about__section">
                            <h2>Supporters</h2>
                            <div>
                            {supporterLogos.map(({ link, alt, src }, i) => {
                                if (link) {
                                    return <a href={link} rel="noopener" key={i}><img className="about__supporter" src={src} alt={alt} /></a>
                                }
                                return <img className="about__supporter" src={src} alt={alt} />
                            })}
                            </div>
                        </section>
                    </article>
                </div>
            </OverlayLayout>
        )
    }
}


const mapStateToProps = state => {
    return {
        isOpen: state.ui.isAboutOpen,
        data: state.app.siteData.about,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutView);