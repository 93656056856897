import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { store } from './store/store.js';

import './styles/normalize.css';

import App from './App.js';

async function loadPolyfills() {
	if (typeof window.IntersectionObserver === 'undefined') {
		await import('intersection-observer')
	}
}

const render = Component => {
	return ReactDOM.render(
		<Provider store={store}>
			<Component />
		</Provider>,
		document.getElementById('root')
	);
};


loadPolyfills();
render(App);

// ReactDOM.render(router, document.getElementById('root'));

if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default
		render(NextApp);
	})
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.register();
