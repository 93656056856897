import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../store/store.js';
import { sortBy as _sortBy } from 'lodash';

import Fuse from 'fuse.js';

import OverlayLayout from '../../layouts/OverlayLayout/OverlayLayout.js';
import ArchiveItem from '../../components/ArchiveItem/ArchiveItem.js';
import ArchiveHeader from '../../components/ArchiveHeader/ArchiveHeader.js';
import ArchiveFilters from '../../components/ArchiveFilters/ArchiveFilters.js';

const ArchiveView = ({ data, filters, sort, searchInput, isOpen, isFiltersOpen, clearFilters, isOnlyFeaturedInArtwork }) => {
    const { items } = data;

    const filteredItems = (() => {

      let visibleItems = [];

      //start filters

      //find out if any filters are in use
      if (filters.length > 0) {

        //if they are, let's declare a variable for which categories (keys) are being filtered
        //this way we can filter across keys with and/or logic, instead of just listing EVERYTHING

        //our items have to match at least one value from EACH key with a filter applied.

        /**/
        //let's start by getting a list of all of the keys being filtered. We will need to make sure our list has no duplicates.

        //create an array
        let keysFiltered = [filters[0].key];
        //compare each filter value's key so we can get a comprehensive list of all the keys filtered.
        for (let i = 1; i < filters.length; i++) {
          let thisKey = filters[i].key;
          let duplicate = 0;

          for (let a = 0; a < filters.length; a++) {
            if (thisKey === keysFiltered[a]) {
              //if it already matches an existing element in the array, mark it as a duplicate so we can ignore it.
              duplicate = 1;
            }
          }
          //if this key hasn't yet been added to our array, we can add it
          if (duplicate === 0) {
            keysFiltered.push(thisKey);
          }
        }
        /**/

        /**/
        //let's make a new array to assign the filter values in use to their key

        let keysArray = [];

        for (let i = 0; i < keysFiltered.length; i++) {
          let thisKey = keysFiltered[i];
          let thisKeyArray = [];
          for (let a = 0; a < filters.length; a++) {
            if (filters[a].key === thisKey) {
              thisKeyArray.push(filters[a].value);
            }
          }
          keysArray.push(thisKeyArray);
        }
        /**/

        /**/
        //now, let's take it into the items!

        for (let i = 0; i < items.length; i++) {

          let thisItem = items[i];
          let keyCount = 0;
          let keyLength = keysArray.length;

          for (let a = 0; a < keysArray.length; a++) {
            //get the name of the key so we can query the item
            let thisKey = keysFiltered[a];

            //see if this key for each item has one of these values
            for (let b = 0; b < keysArray[a].length; b++) {
              let itemValue = thisItem.filters[thisKey].toString().toLowerCase();
              let thisValue = keysArray[a][b].toString().toLowerCase();

              if (itemValue === thisValue) {
                //if the item keyCount >= keyLength, we know it's scored in each key that's been filtered and we can show it!
                keyCount++;
              }
            }
          }

          if (keyCount >= keyLength) {
            visibleItems.push(thisItem);
          }
        }

      } else {
        visibleItems = items;
      }

      //end filters

      //start only featured in artwork

      if (isOnlyFeaturedInArtwork) {
        let onlyInArtworkItems = [];

        visibleItems.forEach(function(item) {
          if (item.isInArtwork) {
            onlyInArtworkItems.push(item);
          }
        });

        visibleItems = onlyInArtworkItems;
      }

      if (searchInput) {

        var options = {
          threshold: 0.2,
          findAllMatches: true,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: ['title', 'filters.author', 'filters.type', 'filters.site', 'filters.department', 'filters.relatesTo', 'filters.format', 'filters.tags']
        };
        var fuse = new Fuse(visibleItems, options);
        var result = fuse.search(searchInput);

        visibleItems = result;
      }

      return visibleItems;

    })();

    const sortedItems = (() => {
        const { key, direction } = sort;
        const asc = _sortBy(filteredItems, [`filters.${key}`, 'title']);

        if (direction === -1) return asc.reverse();
        return asc;
    })();

    return (
        <>  
            <OverlayLayout isOpen={isOpen}>
                <ArchiveHeader />
                <section className="overlay-layout__content">
                  {sortedItems.length ? 
                    <ul className="archive__list">
                        {sortedItems.map((item, i) => <ArchiveItem key={i} { ...item } />)}
                    </ul>
                  :
                    <span onClick={clearFilters} className="archive__no-items">No items matched your filters. Click here to clear.</span>
                  }
                </section>
            </OverlayLayout>
            <OverlayLayout isOpen={isFiltersOpen}>
              <div className="overlay-layout__content">
                <ArchiveFilters />
              </div>
            </OverlayLayout>
        </>
    )
}


const mapStateToProps = state => {
    return {
        isOpen: state.ui.isArchiveOpen,
        isFiltersOpen: state.ui.isArchiveFiltersOpen,
        isOnlyFeaturedInArtwork: state.ui.isOnlyFeaturedInArtwork,
        data: state.app.siteData.archive,
        ...state.filterSearch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveView);