import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { throttle as _throttle } from 'lodash';

import { mapDispatchToProps } from '../../store/store.js';

import Button from '../Core/Button/Button.js';
// import ButtonUnstyled from '../Core/ButtonUnstyled/ButtonUnstyled.js';
// import PlaySymbol from '../../assets/PlaySymbol.js';
// import PauseSymbol from '../../assets/PauseSymbol.js';
import PlayPauseButton from '../PlayPauseButton/PlayPauseButton.js';
import ProgressBar from '../ProgressBar/ProgressBar.js';

// const VideoToggle = ({ videoAccessibilityMode, videoKey, label, isVisible, setVideoAccessibilityMode, setIsVideoPlayerAccessibilityControlsOpen, isVideoPlayerAccessibilityControlsOpen }) => {
//     const isActive = videoAccessibilityMode === videoKey;

//     return (
//         <Button
//             label={`Switch video to ${label}`}
//             tabIndex={isVisible ? 0 : -1}
//             isActive={isActive}
//             isDisabled={isActive}
//             reverseColour
//             onClick={() => setVideoAccessibilityMode(videoKey)}
//         >{label}</Button>
//     )
// }

class PlayerControls extends React.Component {
    interactedTimeout = null;

    state = {
        userHadInteracted: false,
        isMouseOver: false,
    }

    constructor(props) {
        super(props);

        this.onInteracted = _throttle(this.onInteracted.bind(this), 111, { leading: true, trailing: true });

        window.addEventListener('touchstart', this.onInteracted);
        window.addEventListener('touchmove', this.onInteracted);
        window.addEventListener('touchend', this.onInteracted);
        window.addEventListener('mousedown', this.onInteracted);
        window.addEventListener('mousemove', this.onInteracted);
        window.addEventListener('keydown', this.onInteracted);
    }

    componentWillUnmount() {
        if (this.onInteracted) this.onInteracted.cancel();
    }

    onMouseEnter() {
        this.setState({ isMouseOver: true });
    }
    
    onMouseLeave() {
        this.setState({ isMouseOver: false });
    }

    onInteracted() {
        this.setState({ userHadInteracted: true });
        clearTimeout(this.interactedTimeout);
        this.interactedTimeout = setTimeout(() => {
            this.setState({ userHadInteracted: false });
        }, 999);
    }

    render() {
        const { videoAccessibilityMode, setVideoAccessibilityMode, isOverlayOpen, isPlaying, currentTime, duration, currentTitle, togglePlayPause, setCurrentTime, setIsArchiveOpen, toggleFilter, isVideoPlayerSecondaryControlsOpen, setIsVideoPlayerSecondaryControlsOpen } = this.props;
        const { userHadInteracted, isMouseOver, isVideoPlayerAccessibilityControlsOpen } = this.state;
        const isHidden = isOverlayOpen || (!userHadInteracted && !isMouseOver && isPlaying);

        const subtitlesAreOn = videoAccessibilityMode === 'subtitles';

        const className = classNames({
            'player-controls': true,
            'player-controls--is-hidden': isHidden,
            'player-controls--accessibility-options-visible': isVideoPlayerAccessibilityControlsOpen,
            'player-controls--secondary-options-visible': isVideoPlayerSecondaryControlsOpen,
        });

        return (
            <div
                className={className}
                onMouseEnter={() => this.setState({ isMouseOver: true })}
                onMouseLeave={() => this.setState({ isMouseOver: false })}
            >
                <div className="player-controls__inner">
                    <PlayPauseButton onClick={togglePlayPause} isPlaying={isPlaying} 
                    classes={`player-controls__play-pause-button ${isVideoPlayerSecondaryControlsOpen ? 'player-controls__play-pause-button--hidden-on-mobile' : ''}`} />
                    <div className={`player-controls__info-container ${isVideoPlayerSecondaryControlsOpen ? 'player-controls__info-container--additional-controls-visible-on-mobile' : ''}`}>
                        <span>
                            <h4 
                            className={`${!isVideoPlayerSecondaryControlsOpen ? '' : 'player-controls__title--hidden-on-mobile'} player-controls__title`}
                            >{currentTitle}</h4>
                            <Button
                                classes={isVideoPlayerSecondaryControlsOpen ? 'player-controls__additional-control' : 'player-controls__additional-control--hidden-on-mobile'}
                                label="Discover in Archive"
                                tabIndex={isHidden ? -1 : 0}
                                onClick={() => {
                                    toggleFilter({ key: 'chapterInArtwork', value: currentTitle.toLowerCase() });
                                    setIsArchiveOpen(true);
                                }}
                                reverseColour
                            >Discover<span className="hide-below-768"> in Archive</span></Button>
                        </span>
                        <Button
                            classes={isVideoPlayerSecondaryControlsOpen ? 'player-controls__additional-control' : 'player-controls__additional-control--hidden-on-mobile'}
                            label="Toggle Subtitles"
                            tabIndex={isHidden ? -1 : 0}
                            isActive={subtitlesAreOn}
                            reverseColour
                            onClick={() => setVideoAccessibilityMode(subtitlesAreOn ? 'default' : 'subtitles')}
                        >Subtitles
                            <span className="player-controls__toggle-subtitles__state-indicator hide-below-768"> {subtitlesAreOn ? 'on' : 'off'}</span>
                        </Button>
                        <Button
                            classes="player-controls__toggle-additional-controls"
                            label="Show Additional Options"
                            tabIndex={isHidden ? -1 : 0}
                            onClick={() => setIsVideoPlayerSecondaryControlsOpen(isVideoPlayerSecondaryControlsOpen ? false : true)}
                            reverseColour
                        >{isVideoPlayerSecondaryControlsOpen ? '-' : '+'}</Button>
                    </div>
                    <ProgressBar
                        currentTime={currentTime}
                        duration={duration}
                        setCurrentTime={setCurrentTime}
                        isVisible={!isHidden}
                    >
                        <div className="chapter-markers">
                            <span className="chapter-markers__marker"></span>
                            <span className="chapter-markers__marker"></span>
                            <span className="chapter-markers__marker"></span>
                            <span className="chapter-markers__marker"></span>
                            <span className="chapter-markers__marker"></span>
                            <span className="chapter-markers__marker"></span>
                            <span className="chapter-markers__marker"></span>
                            <span className="chapter-markers__marker"></span>
                            <span className="chapter-markers__marker"></span>
                            <span className="chapter-markers__marker"></span>
                            <span className="chapter-markers__marker"></span>
                            <span className="chapter-markers__marker"></span>
                        </div>
                    </ProgressBar>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        videoAccessibilityMode: state.ui.videoAccessibilityMode,
        isOverlayOpen: state.ui.isAboutOpen || state.ui.isArchiveOpen,
        isVideoPlayerSecondaryControlsOpen: state.ui.isVideoPlayerSecondaryControlsOpen,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PlayerControls);