import React from 'react';
import classNames from 'classnames';
import DownArrow from '../../assets/DownArrow.js';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../store/store.js';

const ArchiveHeaderButton = ({ sort, label, sortKey, colIndex, setSort, tabIndex = 0 }) => {
    const { direction, key } = sort;
    const isActive = sortKey === key;
    const colIndexClass = ' archive--col-' + colIndex;

    const className = classNames({
        'archive-header-button': true,
        'archive-header-button--is-active': isActive,
    });

    const accessibilityLabel = (() => {
        if (!isActive || direction === -1) return `Sort by ${label} (ascending)`;
        if (direction === 1) return `Sort by ${label} (descending)`;
    })();
    
    const onClick = (() => {
        if (!isActive || direction === -1) return () => setSort({ key: sortKey, direction: 1 });
        if (direction === 1) return () => setSort({ key: sortKey, direction: -1 });
    })();

    return (
        <div className={colIndexClass}>
            <button tabIndex={tabIndex} onClick={onClick} className={className} aria-label={accessibilityLabel}>
                {label}
                {isActive ?
                    <span className={`archive-header-button__direction-icon archive-header-button__direction-icon--${direction === 1 ? 'up' : 'down'}`}><DownArrow /></span>
                : null}
            </button>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        sort: state.filterSearch.sort,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveHeaderButton);