import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../../store/store.js';

const MetadataTable = ({ rows = [], isVisible }) => {
    return (
        <ul className="metadata-table">
            {rows.map((row, i) => {
                if (!row.value || !row.label) return null;
                return (
                    <li className={`metadata-table__row ${row.mobileOnly ? 'metadata-table__row--mobile-only' : ''}`} key={i}>
                        <span className="metadata-table__label h--small">{row.label}</span>
                        {row.link ?
                            <a tabIndex={isVisible ? 0 : -1} href={row.link} target="_blank"  rel="noopener noreferrer" title={row.label} className="metadata-table__value p--small">{row.value}</a>
                        :
                            <span className="metadata-table__value p--small">{row.value}</span>
                        }
                    </li>

                )
               
            })}
        </ul>
    )
}

const mapStateToProps = state => {
    return {
        isOverlayOpen: state.ui.isAboutOpen || state.ui.isArchiveOpen,
        isArchiveOpen: state.ui.isArchiveOpen,
        isArchiveFiltersOpen: state.ui.isArchiveFiltersOpen,
        archiveFilterCount: state.filterSearch.filters.length,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetadataTable);