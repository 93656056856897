import React from 'react';
import uuidv1 from 'uuid/v1';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { mapDispatchToProps } from '../../store/store.js';
import { fancyTimeFormat } from '../../lib/helpers.js';

import PlaySymbol from '../../assets/PlaySymbol.js';
import PauseSymbol from '../../assets/PauseSymbol.js';
import MetadataTable from '../Core/MetadataTable/MetadataTable.js';

class ArchiveItem extends React.Component {
    state = {
        isExpanded: false, // maybe this makes sense to be moved into redux (ie. expandedArchiveId), but only if we need to know which one's open in other components.
    }

    id = uuidv1();
    contentEl = React.createRef();

    constructor(props) {
        super(props);

        this.loadIntoPlayer = this.loadIntoPlayer.bind(this);
    }

    componentDidMount() {
        this.disableLinks();
    }

    componentDidUpdate(lastProps, lastState) {
        const { isArchiveOpen } = this.props;
        const { isExpanded } = this.state;

        if (!isArchiveOpen && lastProps.isArchiveOpen) {
            this.setState({ isExpanded: false });
        }

        if ((isExpanded && !lastProps.isExpanded) || (isExpanded && isArchiveOpen && !lastProps.isArchiveOpen)) {
            this.enableLinks();
        }

        if ((!isExpanded && lastProps.isExpanded) || (isExpanded && !isArchiveOpen && lastProps.isArchiveOpen)) {
            this.disableLinks();
        }
    }

    loadIntoPlayer() {
        const { title, audio, setArchiveAudioPlayerItem } = this.props;

        setArchiveAudioPlayerItem({
            id: this.id,
            title,
            ...audio[0],
        });
    }

    enableLinks() {
        const linkEls = [...this.contentEl.current.querySelectorAll('a')];
        linkEls.forEach(el => el.tabIndex = null);
    }

    disableLinks() {
        const linkEls = [...this.contentEl.current.querySelectorAll('a')];
        linkEls.forEach(el => el.tabIndex = "-1");
    }

    render() {
        const { title, filters, descriptionIntro, descriptionMain, metadata, isInArtwork, startsAt, endsAt, audio, images, currentArchivePlayerId, setArchiveAudioPlayerItem, isArchiveOpen, isArchiveFiltersOpen } = this.props;
        const { type, site, department, format, author, relatesTo } = filters;
        const { isExpanded } = this.state;
        const isPlaying = currentArchivePlayerId === this.id;

        const className = classNames({
            'archive-item': true,
            'archive-item--is-playing': isPlaying,
        });

        const classNameMain = classNames({
            'archive-item__main': true,
            'archive-item__main--expanded': isExpanded,
        });

        const metadataRows = [
            { label: 'Type', value: type.join(', '), mobileOnly: true },
            { label: 'Format', value: format.join(', ') },
            { label: 'Site', value: site.join(', '), mobileOnly: true },
            { label: 'Department', value: department.join(', ') },
            { label: 'Author', value: author.join(', ') },
            { label: 'Relates to', value: relatesTo.join(', ')  },
            ...metadata
        ];

        if (isInArtwork && startsAt && endsAt) {
            metadataRows.push({ label: 'In Video', value: `${fancyTimeFormat(startsAt)}—${fancyTimeFormat(endsAt)}` })
        }

        const playPause = () => {
            if (isPlaying) {
                setArchiveAudioPlayerItem(null);
            } else {
                this.loadIntoPlayer();
            }
        }
        return (
            <li className={className} aria-hidden={!isExpanded}>
                <div className="archive-item__header">
                    { audio[0] ? 
                        <div
                            className={`archive--col-0 archive-item__header-title-container ${audio[0] ? 'archive-item__header-title-container--audio-button' : '' } ${isPlaying ? 'archive-item__header-title-row--playing' : ''}`}
                            aria-label={isPlaying ? "Pause" : "Play"}
                            role="button"
                            tabIndex={isArchiveOpen && !isArchiveFiltersOpen ? 0 : -1}
                            onClick={() => {
                                playPause();
                                this.setState({ isExpanded: !isExpanded });
                            }}
                            onKeyDown={(e) => {
                                if (e.key.toLowerCase() === 'enter') {
                                    playPause();
                                    this.setState({ isExpanded: !isExpanded });
                                }
                            }}
                        >
                            {isPlaying ?
                                <span className="archive-item__play-pause-icon archive-item__play-pause-icon--pause" >
                                    <PauseSymbol />
                                </span>
                            :
                                <span className="archive-item__play-pause-icon archive-item__play-pause-icon--play">
                                    <PlaySymbol />
                                </span>
                            }
                            <h3 className="archive-item__header-title">{title}</h3>
                        </div>
                        :
                        <div
                            className="archive--col-0 archive-item__header-title-container"
                            role="button"
                            aria-label="Toggle item"
                            tabIndex={isArchiveOpen && !isArchiveFiltersOpen ? 0 : -1}
                            onClick={() => {
                                this.setState({ isExpanded: !isExpanded });
                            }}
                            onKeyDown={(e) => {
                                if (e.key.toLowerCase() === 'enter') {
                                    playPause();
                                    this.setState({ isExpanded: !isExpanded });
                                }
                            }}
                        >
                            <h3 className="archive-item__header-title">{title}</h3>
                        </div>
                    }
                    <div className="archive--col-1 archive-item__header-type">
                        {type}
                    </div>
                    <div className="archive--col-2 archive-item--col-last archive-item__header-site">
                        <p>{site}</p>
                        <button
                            className="archive-item__expand-button"
                            label={isExpanded ? 'Close' : 'Expand'}
                            tabIndex={isArchiveOpen && !isArchiveFiltersOpen ? 0 : -1}
                            onClick={() => this.setState({ isExpanded: !isExpanded })}
                        ><span>{isExpanded ? '-' : '+'}</span></button>
                    </div>
                </div>
                <article className={classNameMain}>
                    {images.map((image, i) => (
                        <div className="archive-item__image-wrapper" key={image.src}>
                            <a tabIndex={isExpanded && isArchiveOpen && !isArchiveOpen ? 0 : -1} className="archive-item__image-link" href={image.src} target="_blank" rel="noopener">
                                <img className="archive-item__main-img" alt={image.alt} srcSet={image.srcset} src={image.src} sizes="80vw" />
                                <span className="archive-item__image-link">View Full Image</span>
                            </a>
                        </div>
                    ))}
                    <div className="archive-item__text-content" ref={this.contentEl}>
                        <div className="archive-item__description-intro" dangerouslySetInnerHTML={{ __html: descriptionIntro }} />
                        <div className="archive-item__description-main" dangerouslySetInnerHTML={{ __html: descriptionMain }} />
                        <MetadataTable isVisible={isExpanded} rows={metadataRows} />
                    </div>
                </article>
            </li>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentArchivePlayerId: state.ui.archiveAudioPlayerItem ? state.ui.archiveAudioPlayerItem.id : null,
        isArchiveOpen: state.ui.isArchiveOpen,
        isArchiveFiltersOpen: state.ui.isArchiveFiltersOpen,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ArchiveItem);