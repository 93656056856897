import React from 'react';
import classNames from 'classnames'

const Button = ({ onClick, children, classes = '', isDisabled, isActive, label, tabIndex = 0, reverseColour }) => {
    const className = classNames({
        'core-button': true,
        'core-button--disabled': isDisabled,
        'core-button--active': isActive,
        'core-button--reverse-color': reverseColour,
    });
    return (
        <button
            className={`${className} ${classes}`}
            disabled={isDisabled}
            aria-label={label}
            tabIndex={tabIndex}
            aria-hidden={tabIndex === -1}
            onClick={onClick}
        >{children}</button>
    )
}

export default Button;