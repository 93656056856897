import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from './store/store.js';

import { DATA_API_ENDPOINT } from './config.js';

import './styles/global.scss';

import AboutView from './views/About/About.js';
import ArchiveView from './views/Archive/Archive.js';
import PlayerView from './views/Player/Player.js';

import Header from './components/Header/Header.js';
import ArchiveAudioPlayer from './components/ArchiveAudioPlayer/ArchiveAudioPlayer.js';


class App extends React.Component {
	state = {
		isSiteDataLoaded: null,
	}

	constructor(props) {
		super(props);

		this.onKeypress = this.onKeypress.bind(this);
	}

	onKeypress({ which }) {
		const { closeOverlays } = this.props;
		switch (which) {
			case 27:
				closeOverlays();
				break;
			default:
				break;
		}
	}

	async fetchSiteData() {
		const { setSiteData } = this.props;

		const response = await fetch(DATA_API_ENDPOINT);
		const json = await response.json();

		setSiteData(json);
	}

	componentDidMount() {
		window.addEventListener('keydown', this.onKeypress);
		this.fetchSiteData();
	}

	render() {
		const { isReady } = this.props;

		if (!isReady) return null;

		return (
			<div className="app">
			<PlayerView />
			<Header />
			<main className="app__main">
				<AboutView />
				<ArchiveView />
				<ArchiveAudioPlayer />
			</main>
			</div>
		);
	}
}


const mapStateToProps = state => ({ isReady: !!state.app.siteData  });

export default connect(mapStateToProps, mapDispatchToProps)(App);
