import React from 'react';
import classNames from 'classnames';
import { clamp } from 'lodash';
import { fancyTimeFormat } from '../../lib/helpers.js';

class View extends React.Component {
	render() {
		const { userSeeking, userJustFinishedSeeking, currentTime, duration, percentComplete, progressBarOuterWidth, mouseHoverPos, isMouseOver, onMouseUp, onMouseDown, onMouseLeave, onMouseMove, onKeyDown, isVisible, children } = this.props;
		// TODO: pass in as props
		const currentTimeFormatted = (userSeeking === false && userJustFinishedSeeking === false) ? fancyTimeFormat(Math.round(currentTime)) : fancyTimeFormat(Math.round(mouseHoverPos / 100 * duration));
		const transformInPx = (userSeeking === false && userJustFinishedSeeking === false) ? Math.round((percentComplete / 100) * progressBarOuterWidth) : Math.round((mouseHoverPos / 100) * progressBarOuterWidth);
        const highlightTransformInPx = Math.round((mouseHoverPos / 100) * progressBarOuterWidth);
        
        const outerClassName = classNames({
            'progress-bar__outer': true,
            'progress-bar__outer--is-grabbing': userSeeking
        });

        const innerClassName = classNames({
            'progress-bar__inner': true,
            'progress-bar__inner--is-hidden': userSeeking || isMouseOver
        });

        const trackingHighlightClassName = classNames({
            'progress-bar__tracking-highlight': true,
            'progress-bar__tracking-highlight--is-visible': userSeeking || isMouseOver
        });

        const cursorClassName = classNames({
            'progress-bar__cursor': true,
            'progress-bar__cursor--is-visible': isMouseOver
        });

		return (
			<div className="progress-bar">
				<div
					onMouseDown={onMouseDown}
					onTouchStart={onMouseDown}
					onMouseUp={onMouseUp}
					onTouchEnd={onMouseUp}
					onMouseLeave={onMouseLeave}
					onTouchCancel={onMouseLeave}
					onMouseMove={onMouseMove}
					onTouchMove={onMouseMove}
					onKeyDown={onKeyDown}
					tabIndex={isVisible ? 0 : 1}
					aria-valuenow={Math.round(percentComplete)}
					aria-valuemin="0"
					aria-valuemax="100"
					role="progressbar"
                    ref="progressBarOuter"
					className={outerClassName}
				>
					{ children }
					<span className={innerClassName} style={{ width: `${transformInPx}px` }} />
					<span className={trackingHighlightClassName} style={{ width: `${highlightTransformInPx}px` }} />
					<span className={cursorClassName} style={{transform: `translateX(${transformInPx}px)`, WebkitTransform: `translateX(${transformInPx}px)`, MozTransform: `translateX(${transformInPx}px)`, MsTransform: `translateX(${transformInPx}px)`, OTransform: `translateX(${transformInPx}px)`}} />
				</div>
                <span className="progress-bar__time">{currentTimeFormatted} / {fancyTimeFormat(Math.round(duration))}</span>
			</div>
		);
	}
};


class ProgressBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			progressBarOuterWidth: 0,
            userSeeking: false,
            isMouseOver: false,
			userJustFinishedSeeking: false,
			mouseHoverPos: 0,
			lastTouchX: false,
			percentComplete: 0,
			currentTime: 0,
			duration: 0,
		};

		this.onResize = this.onResize.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.getVideoTimes = this.getVideoTimes.bind(this);
	}

	componentWillMount() {
		this.getVideoTimes();
	}
    
	componentDidMount() {
    	window.addEventListener('resize', this.onResize);
		this.onResize();
	}

	componentWillUnmount() {
		clearTimeout(this.finishedSeekingTimeout);
		window.removeEventListener('resize', this.onResize);
	}

	onResize() {
		const progressBarOuterWidth = this.refs.view.refs.progressBarOuter ? this.refs.view.refs.progressBarOuter.clientWidth : 0;
		const offsetLeft = this.refs.view.refs.progressBarOuter.getBoundingClientRect().left;

		this.setState({
			progressBarOuterWidth,
			offsetLeft,
		});
	}

	componentDidUpdate(lastProps) {
		if (lastProps.currentTime !== this.props.currentTime || lastProps.duration !== this.props.duration) this.getVideoTimes();
	}

	getVideoTimes() {
		const duration = this.props.duration || 0;
		const currentTime = clamp((this.props.currentTime || 0), 0, duration);
		const percentComplete = (currentTime / duration) * 100;

		this.setState({
			currentTime,
			duration,
			percentComplete,
		});
	}

	seekToTime(x) {
		const { setCurrentTime } = this.props;

		const pos = x - this.state.offsetLeft;
		let mouseHoverPos = pos / this.state.progressBarOuterWidth * 100;
		if (mouseHoverPos > 100) mouseHoverPos = 100;
		if (mouseHoverPos < 0) mouseHoverPos = 0;

		const time = this.state.duration * (mouseHoverPos / 100);
		this.setState({ mouseHoverPos });

		setCurrentTime(time);
	}

	onKeyDown(e) {
		const { setCurrentTime, currentTime } = this.props;
		if (e.key.toLowerCase() === 'arrowleft') {
			setCurrentTime(currentTime - 10);
		}

		if (e.key.toLowerCase() === 'arrowright') {
			setCurrentTime(currentTime + 10);
		}
	}

	onMouseDown(e) {
		const x = e.touches ? e.touches[0].clientX : e.clientX;
		if (e.touches) this.setState({ lastTouchX: x });
		this.setState({ userSeeking: true });
		const mouseHoverPos = 100 * (x - this.state.offsetLeft) / this.state.progressBarOuterWidth;
		this.setState({ mouseHoverPos });
	}

	onMouseUp(e) {
		const x = this.state.lastTouchX ? this.state.lastTouchX : e.clientX;
		this.setState({ lastTouchX: false });
		if (this.state.userSeeking) this.seekToTime(x);
		this.setState({ userJustFinishedSeeking: true, userSeeking: false, isMouseOver: false });

		this.finishedSeekingTimeout = setTimeout(() => {
			this.setState({ userJustFinishedSeeking: false });
		}, 33);
	}

	onMouseLeave() {
		this.setState({ userSeeking: false, isMouseOver: false });
	}

	onMouseMove(e) {
		if (this.state.userJustFinishedSeeking) return;

		const x = e.touches ? e.touches[0].clientX : e.clientX;
		if (e.touches) this.setState({ lastTouchX: x });
		let mouseHoverPos = 100 * (x - this.state.offsetLeft) / this.state.progressBarOuterWidth;
		if (mouseHoverPos > 100) mouseHoverPos = 100;
		if (mouseHoverPos < 0) mouseHoverPos = 0;

		this.setState({ mouseHoverPos, isMouseOver: true });
	}

	render() {
		const { isVisible } = this.props;

		return (
			<View ref="view" {...this.state}
				isVisible={isVisible}
				onMouseUp={this.onMouseUp}
				onMouseDown={this.onMouseDown}
				onMouseLeave={this.onMouseLeave}
				onMouseMove={this.onMouseMove}
				onKeyDown={this.onKeyDown}
				children={this.props.children}
			/>
		)
	}
}

export default ProgressBar;
