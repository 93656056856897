import React from 'react';

const PlaySymbol = () => {
	return (
		<svg 
			viewBox="0 0 12 13"
			version="1.1"
			className="play-symbol"
		>
			<g id="MainNav">
				<g id="Archive--Playing" transform="translate(-26.000000, -121.000000)">
					<polygon id="Triangle" transform="translate(32.000000, 127.500000) rotate(-270.000000) translate(-32.000000, -127.500000) " points="32 122 38.5 133 25.5 133"></polygon>
				</g>
			</g>
		</svg>
	)
}

export default PlaySymbol;