export function setFeatureSupport(featureSupport) {
	return {
		type: 'SET_FEATURE_SUPPORT',
		payload: featureSupport,
	};
}

export function setDevice(device) {
	return {
		type: 'SET_DEVICE',
		payload: device,
	};
}

export function setSiteData(json) {
	return {
		type: 'SET_SITE_DATA',
		payload: json,
	};
}

export function setSearchInput(text) {
	return {
		type: 'SET_SEARCH_INPUT',
		payload: text,
	};
}

export function setSort(option) {
	return {
		type: 'SET_SORT',
		payload: option,
	};
}

export function setIsAboutOpen(isOpen) {
	return {
		type: 'SET_IS_ABOUT_OPEN',
		payload: isOpen,
	};
}

export function setIsArchiveOpen(isOpen) {
	return {
		type: 'SET_IS_ARCHIVE_OPEN',
		payload: isOpen,
	};
}

export function setIsVideoPlayerSecondaryControlsOpen(isOpen) {
	console.log(isOpen)
	return {
		type: 'SET_IS_VIDEO_PLAYER_SECONDARY_CONTROLS_OPEN',
		payload: isOpen,
	};
}

export function closeOverlays() {
	return {
		type: 'CLOSE_OVERLAYS',
		payload: null,
	};
}

export function setArchiveAudioPlayerItem(data) {
	return {
		type: 'SET_ARCHIVE_AUDIO_PLAYER_ITEM',
		payload: data,
	}
}

export function setVideoAccessibilityMode(mode) {
	return {
		type: 'SET_VIDEO_ACCESSIBILITY_MODE',
		payload: mode,
	}
}

export function setIsArchiveFiltersOpen(isOpen) {
	return {
		type: 'SET_IS_ARCHIVE_FILTERS_OPEN',
		payload: isOpen,
	};
}

export function setIsVideoPlayerAccessibilityControlsOpen(isOpen) {
	return {
		type: 'SET_IS_VIDEO_PLAYER_ACCESSIBILITY_CONTROLS_OPEN',
		payload: isOpen,
	};
}

export function toggleFilter(key) {
	return {
		type: 'TOGGLE_FILTER',
		payload: key,
	};
}

export function toggleOnlyFeaturedInArtwork(isOpen) {
	return {
		type: 'TOGGLE_ONLY_FEATURED_IN_ARTWORK',
		payload: isOpen,
	};
}

export function clearFilters() {
	return {
		type: 'CLEAR_FILTERS',
		payload: true,
	};
}