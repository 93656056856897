import React from 'react';

const DownArrow = () => {
	return (
		<svg className="downArrow" viewBox="0 0 8 7">
			<title>DownArrow</title>
			<polygon points="4 7 0 0 8 0 4 7"/>
		</svg>
	)
}

export default DownArrow;